import styled, { css } from 'styled-components'
import { rgba, maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'

const Calculator = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    background: #FFFFFF;
    ${p => p.main && css`
        padding: 30px;
        box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 32px rgba(61, 75, 86, 0.1);
    `}
    border-radius: ${p => p.noLeftRadius ? 0 : "8px"} ${p => p.noRightRadius ? 0 : "8px"} 8px 8px;
    ${maxWidth(vars.breakpoints.tablet)`
        gap: 32px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        display: block;
        padding: 0;
        box-shadow: none;
        ${p => p.main && "padding-bottom: 48px;"}
    `}
`

Calculator.Wrapper = styled.div`
    position: relative;
    z-index: 1;
    ${p => p.mb && "margin-bottom: -150px;"}
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 0;
    `}
`

Calculator.Tabs = styled.div`
    position: relative;
    display: flex;
    z-index: 1;
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 24px;
        margin-right: -20px;
        border-bottom: 1px solid ${vars.theme.outlineGrey};
        overflow-x: auto;
        overflow-y: hidden;
    `}
`

Calculator.Tab = styled.div`
    flex: 1;
    display: flex;
    padding: 24px 24px 44px;
    ${p => p.active && css`
        background: #FFFFFF;
        box-shadow: 3px -12px 12px 6px rgba(61,75,86,0.04);
        ${Calculator.TabTitle} {
            color: ${vars.theme.black};
        }
    `}
    ${Calculator.TabTitle} {
        color: ${vars.theme.darkGrey};
    }
    border-radius: 6px 6px 0px 0px;
    cursor: pointer;
    transition: .3s;
    ${maxWidth(vars.breakpoints.tablet)`
        padding: 16px 16px 24px;
        align-items: center;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        position: relative;
        flex: initial;
        margin-right: 24px;
        padding: 0 0 16px;
        box-shadow: none;
        color: ${p => p.active ? vars.theme.black : vars.theme.darkGrey};
        white-space: nowrap;
        cursor: pointer;
        transition: .3s;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: ${vars.theme.red};
            transform: scale(0);
            transform-origin: ${p => p.active ? "0 100%" : "100% 0"};
            transition: transform 0.2s linear 0s;
        }
        ${p => p.active && css`
            &:after {
                transform: none;
            }
        `}
    `}
`

Calculator.TabIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    background: ${vars.theme.lightGrey};
    border-radius: 8px;
    
    svg {
        fill: #fff;
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        display: none;
    `}
`

Calculator.TabContent = styled.div``

Calculator.TabTitle = styled.div`
    margin-bottom: 4px;
    transition: all .3s;
    ${maxWidth(vars.breakpoints.tablet)`
        margin: 0;
    `}
`

Calculator.TabText = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: ${vars.theme.darkGrey};
    ${maxWidth(vars.breakpoints.tablet)`
        display: none;
    `}
`

Calculator.Form = styled.div`
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 24px;
    `}
`

Calculator.Info = styled.div`
    ${p => p.main ? css`
        padding: 20px;
        border: 1px solid ${vars.theme.outlineGrey};
    ` : css`
        padding: 32px;
        box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 32px rgba(61, 75, 86, 0.1);
    `}
    border-radius: 8px;
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: 0;
        border: none;
        box-shadow: none;
    `}
`

Calculator.InfoTop = styled.div`
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 24px;
        padding: 16px;
        border: 1px solid ${vars.theme.outlineGrey};
        border-radius: 8px;
    `}
`

Calculator.Conditions = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    ${maxWidth(vars.breakpoints.mTablet)`
        margin: 0;
    `}
`

Calculator.Condition = styled.div`
    &:not(:last-child) {
        padding-right: 30px;
    }
    ${p => p.promo && css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 230px;
        margin-left: auto;
        padding: 12px;
        border: 1px solid ${vars.theme.outlineGrey};
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
        color: ${vars.theme.darkGrey};
        svg {
            margin-left: 16px;
        }
    `}
    ${maxWidth(1285)`
        ${p => p.promo && css`
            max-width: 100%;
            margin: 16px 0 0;
        `}
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        flex: inherit;
        ${p => p.promo && css`
            max-width: 250px;
            margin: 0 0 0 auto;
            padding: 10px 12px;
        `}
    `}
    ${maxWidth(600)`
        ${p => p.promo && css`
            max-width: 100%;
            margin: 16px 0 0;
            padding: 12px;
        `}
    `}
`

Calculator.ConditionValue = styled.div`
    font-weight: 500;
    font-size: 24px;
    ${maxWidth(vars.breakpoints.sDesktop)`
        font-size: 20px;
    `}
`

Calculator.InfoBottom = styled.div`
    display: grid;
    grid-template-columns: 180px 1fr;
    align-items: center;
    gap: 24px;
    ${maxWidth(vars.breakpoints.sDesktop)`
        grid-template-columns: 1fr;
        gap: 20px;
    `}
`

Calculator.InfoText= styled.div`
    font-size: 12px;
    line-height: 20px;
    color: ${vars.theme.darkGrey};
`

Calculator.Subtitle = styled.div`
    font-size: 18px;
    line-height: 30px;
    color: ${vars.theme.darkGrey};
`

export default Calculator
