import { useState, useEffect, useRef } from 'react'
import { StyledMobileNav, StyledButton } from '@/components/styles'
import { SvgIcon, RouteLink } from '@/components'
import Link from 'next/link'
import Router from 'next/router'
import { useInfo } from '@/contexts/InfoContext'
import { useAuth } from '@/contexts/AuthContext'

const nav = [
    { id: 9, title: 'Пресс-центр', link: '/press-center' },
    {
        id: 10,
        title: 'О компании',
        submenu: [
            {
                id: 11,
                title: 'О компании',
                link: '/about-company',
            },
            {
                id: 12,
                title: 'Карьера',
                link: '/career',
            },
            {
                id: 13,
                title: 'Документы',
                link: '/documents',
            },
            {
                id: 14,
                title: 'Партнеры',
                text: '',
                link: '/partners',
            }
        ],
    },
    {
        id: 15,
        title: 'Контакты',
        submenu: [
            {
                id: 16,
                title: 'Контакты отделений',
                link: '/contacts?view=list',
            },
            {
                id: 17,
                title: 'Мобильный офис',
                link: '/mobile-office',
            },
        ],
    },
]

export default function MobileNav({
    top,
    isOpen,
    toggleMenuVisibility,
    onBtnClick,
    setAuthModalIsOpen,
}) {
    const { categories } = useInfo()
    const { user } = useAuth()
    const [visiblePhones, setVisiblePhones] = useState(false)

    useEffect(() => {
        window.addEventListener('click', onClickOutside)
        return () => window.removeEventListener('click', onClickOutside)
    }, [isOpen])

    useEffect(() => {
        window.addEventListener('click', onPhonesClickOutside)
        return () => window.removeEventListener('click', onPhonesClickOutside)
    }, [visiblePhones])

    const wrapper = useRef(null)
    const phonesWrapper = useRef(null)

    const onClickOutside = (e) => {
        if (isOpen && wrapper.current === e.target) {
            toggleMenuVisibility(e)
        }
    }

    const onPhonesClickOutside = (e) => {
        if (visiblePhones && phonesWrapper.current === e.target) {
            setVisiblePhones(false)
        }
    }

    return (
        <StyledMobileNav visible={isOpen} ref={wrapper}>
            <StyledMobileNav.Menu smallPadding={!top}>
                <StyledMobileNav.MenuInner>
                    <StyledMobileNav.Level visible={true}>
                        <StyledMobileNav.LevelInner>
                            <StyledMobileNav.Layout>
                                <StyledMobileNav.List fixedHeight>
                                    <MobileNavItem
                                        grey
                                        title={
                                            <>
                                                Личный кабинет{' '}
                                                <SvgIcon name="exit" width="17" height="18" />
                                            </>
                                        }
                                        onClick={() => {
                                            toggleMenuVisibility()
                                            user ? Router.push('/appeal') : setAuthModalIsOpen(true)
                                        }}
                                    />
                                    {categories.header.map((item) =>
                                        item.children?.length > 0 ? (
                                            <MobileNavExtraListItem key={item.id} title={item.name}>
                                                {item.children.map(
                                                    (subItem) =>
                                                        subItem.has_page &&
                                                        (subItem.children?.length > 0 ? (
                                                            <MobileNavSwitch
                                                                key={subItem.id}
                                                                title={subItem.name}
                                                                slug={
                                                                    subItem.has_page && subItem.slug
                                                                }
                                                                onClick={toggleMenuVisibility}
                                                                smallTitle
                                                            >
                                                                {generateLevelJSX(
                                                                    subItem.children,
                                                                    toggleMenuVisibility,
                                                                )}
                                                            </MobileNavSwitch>
                                                        ) : (
                                                            <MobileNavItem
                                                                key={subItem.id}
                                                                slug={
                                                                    subItem.has_page && subItem.slug
                                                                }
                                                                title={subItem.name}
                                                                smallTitle
                                                                onClick={toggleMenuVisibility}
                                                            />
                                                        )),
                                                )}
                                            </MobileNavExtraListItem>
                                        ) : (
                                            <MobileNavItem
                                                key={item.id}
                                                slug={item.has_page && item.slug}
                                                title={item.name}
                                                onClick={toggleMenuVisibility}
                                            />
                                        ),
                                    )}
                                    {nav.map((item) =>
                                        item.submenu ? (
                                            <MobileNavExtraListItem
                                                key={item.id}
                                                title={item.title}
                                            >
                                                {item.submenu.map((subItem) => {
                                                    return (
                                                        <MobileNavItem
                                                            key={subItem.id}
                                                            href={subItem.link}
                                                            title={subItem.title}
                                                            smallTitle
                                                            onClick={toggleMenuVisibility}
                                                        />
                                                    )
                                                })}
                                            </MobileNavExtraListItem>
                                        ) : (
                                            <MobileNavItem
                                                key={item.id}
                                                href={item.link}
                                                title={item.title}
                                                onClick={toggleMenuVisibility}
                                            />
                                        ),
                                    )}
                                </StyledMobileNav.List>
                                <StyledMobileNav.Contacts>
                                    <StyledMobileNav.PhoneToggle
                                        onClick={() => setVisiblePhones(true)}
                                    >
                                        <a href="tel:+375298883222">+375 (29) 888-3-222 (МТС)</a>
                                        <StyledMobileNav.PhoneLabel>
                                            Телефон для связи
                                        </StyledMobileNav.PhoneLabel>
                                    </StyledMobileNav.PhoneToggle>
                                    <StyledMobileNav.Dropdown
                                        isVisible={visiblePhones}
                                        ref={phonesWrapper}
                                    >
                                        <StyledMobileNav.DropdownInner>
                                            <StyledMobileNav.DropdownItem>
                                                <a href="tel:+375298883222">+375 (29) 888-3-222 (МТС)</a>
                                            </StyledMobileNav.DropdownItem>
                                            <StyledMobileNav.DropdownItem>
                                                <a href="tel:+375291226800">+375 (29) 122-6-800 (А1)</a>
                                            </StyledMobileNav.DropdownItem>
                                        </StyledMobileNav.DropdownInner>
                                    </StyledMobileNav.Dropdown>
                                    <StyledButton bg="grey" width="full" onClick={onBtnClick}>
                                        Связаться
                                    </StyledButton>
                                </StyledMobileNav.Contacts>
                            </StyledMobileNav.Layout>
                        </StyledMobileNav.LevelInner>
                    </StyledMobileNav.Level>
                </StyledMobileNav.MenuInner>
            </StyledMobileNav.Menu>
        </StyledMobileNav>
    )
}

function MobileNavItem({ slug, href, title, smallTitle, children, ...props }) {
    return (
        <StyledMobileNav.Item grey={props.grey}>
            {href ? (
                <Link href={href}>
                    <StyledMobileNav.Link {...props} small={smallTitle}>
                        {title}
                    </StyledMobileNav.Link>
                </Link>
            ) : slug ? (
                <RouteLink slug={slug}>
                    <StyledMobileNav.Link {...props} small={smallTitle}>
                        {title}
                    </StyledMobileNav.Link>
                </RouteLink>
            ) : (
                <StyledMobileNav.Link {...props} as="div" small={smallTitle}>
                    {title}
                </StyledMobileNav.Link>
            )}

            {children}
        </StyledMobileNav.Item>
    )
}

function MobileNavExtraListItem({ title, children, ...props }) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <StyledMobileNav.Item>
            <StyledMobileNav.Link
                {...props}
                as="div"
                arrow
                isOpen={isOpen}
                onClick={() => setIsOpen(!isOpen)}
            >
                {title}
            </StyledMobileNav.Link>
            <StyledMobileNav.ExtraList isOpen={isOpen}>{children}</StyledMobileNav.ExtraList>
        </StyledMobileNav.Item>
    )
}

function generateLevelJSX(items, onClick) {
    const traverse = (items) => {
        return items.map((item) => {
            const { id, name, slug, children = [], has_page } = item

            if (has_page) {
                if (!children || children.length === 0) {
                    return (
                        <MobileNavItem
                            key={id}
                            slug={slug}
                            title={name}
                            smallPadding
                            onClick={onClick}
                        />
                    )
                } else {
                    return (
                        <StyledMobileNav.Item key={id}>
                            <MobileNavSwitch
                                title={name}
                                slug={slug}
                                onClick={onClick}
                                arrow={children}
                            >
                                {children && children.length > 0 && traverse(children)}
                            </MobileNavSwitch>
                        </StyledMobileNav.Item>
                    )
                }
            }
        })
    }

    return traverse(items)
}

function MobileNavSwitch({ title, smallTitle, slug, children = [], onClick, arrow }) {
    const [isVisible, setIsVisible] = useState(false)

    return (
        <>
            <StyledMobileNav.Link
                as="button"
                small={smallTitle}
                smallPadding
                arrow={arrow}
                onClick={() => setIsVisible(!isVisible)}
            >
                {title}
            </StyledMobileNav.Link>

            <StyledMobileNav.Level visible={isVisible}>
                <StyledMobileNav.LevelInner>
                    <StyledMobileNav.Back onClick={() => setIsVisible(!isVisible)}>
                        <StyledMobileNav.BackIcon>
                            <SvgIcon name="arrow-back" width="24" height="24" />
                        </StyledMobileNav.BackIcon>
                        <span>Назад</span>
                    </StyledMobileNav.Back>

                    <ul>
                        <MobileNavItem
                            slug={slug}
                            title={title}
                            smallPadding
                            activeLevelLink
                            onClick={onClick}
                        />
                        {children}
                    </ul>
                </StyledMobileNav.LevelInner>
            </StyledMobileNav.Level>
        </>
    )
}
