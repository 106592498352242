import { StyledReviews, StyledContainer, StyledTitle } from '@/components/styles';
import { SvgIcon } from '@/components'
import { useEffect } from 'react';
const ReviewsBlock =  ({reviews, whiteContent}) => {
    reviews = Object.values(reviews.reviews).filter(review => typeof review  === 'object')

    return (
        <StyledContainer>
            <StyledReviews.Wrap white={whiteContent}>
                <StyledTitle white={whiteContent} as="h1">Отзывы о компании</StyledTitle>
                <StyledReviews white={whiteContent}>
                    {reviews.map((review) => (
                    <StyledReviews.Item>
                        <StyledReviews.Title white={whiteContent}>
                            <StyledReviews.Photo>
                                <img src={review.photo} alt={review.name} />
                            </StyledReviews.Photo>
                            <h2>{review.name}</h2>
                        </StyledReviews.Title>
                        <StyledReviews.Body>
                            <StyledReviews.Rating data-raiting={review.raiting}>
                                {[...Array(review.raiting)].map((i) => (
                                    <SvgIcon name="full-star" width="24" height="24" />
                                ))}
                                {[...Array(5 - review.raiting)].map((i) => (
                                    <SvgIcon name="empty-star" width="24" height="24" />
                                ))}
                            </StyledReviews.Rating>
                            {review.content}
                            <StyledReviews.Link>
                                <a href="https://yandex.by/maps/org/tsentr_finansovykh_konsultatsiy/66064182565/reviews/?ll=27.581279%2C53.943165&z=16">Источник</a>
                            </StyledReviews.Link>
                        </StyledReviews.Body>
                    </StyledReviews.Item>
                    ))}
                </StyledReviews>
            </StyledReviews.Wrap>
        </StyledContainer>
    )
}

ReviewsBlock.options = {}

ReviewsBlock.getInitialProps = async ({ api }) => {
    const { data } = await api.reviews()
    return { reviews: data }
}

export default ReviewsBlock